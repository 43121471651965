// レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
//-------------------------------------------------------------
function checkDeviceAndWidthFunc(){

  var pgwBrowser = $.pgwBrowser();
  var jsBr = pgwBrowser.browser.group;
  var jsBrVer = pgwBrowser.browser.group + pgwBrowser.browser.majorVersion;
  var jsOS = pgwBrowser.os.group;
  var jsOsVer = pgwBrowser.os.group + pgwBrowser.os.majorVersion;
  //console.info('横幅:' + pgwBrowser.viewport.width + 'px / 縦幅:' + pgwBrowser.viewport.height + 'px');
  //console.info(pgwBrowser.userAgent);
  $('html').addClass(jsBr + ' ' + jsBrVer + ' ' + jsOS + ' ' + jsOsVer);
}

function mq(device) {
  if (window.matchMedia('(max-width:768px)').matches && device == "sp") {
    return true;
  } else if (window.matchMedia('(min-width:769px)').matches && device == "pc") {
    return true;
  }
  return false;
}


// load判定関数（PC,SP共通処理）
//-------------------------------------------------------------
function loadedPageFunc (){

  var $pageBody = $('body');
  $pageBody.addClass('loaded');

}


//page top関数（PC,SP共通処理）
//-------------------------------------------------------------
function goToPageTopFunc($pageTopId){

  var $pageTopId = $($pageTopId);
  $pageTopId.click(function() {
    $("html, body").animate({scrollTop:0}, 500, "swing");
    return false;
  });

}

//ページ内スクロール関数（PC,SP共通処理）
//-------------------------------------------------------------
function smoothScrollMoveFunc(goToClassName){

  var $goToClassName = $(goToClassName);

  var headerHeight = $('.header').outerHeight(); //ヘッダー固定の場合は必要
  var urlHash = location.hash;
  if(urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function(){
      var target = $(urlHash);
      var position = target.offset().top - headerHeight; //ヘッダー固定の場合は必要headerHeightが必要
      $('body,html').stop().animate({scrollTop:position}, 500);
    }, 100);
  }
  $goToClassName.click(function() {
    var href= $(this).attr("href");
    var target = $(href);
    var position = target.offset().top - headerHeight; //ヘッダー固定の場合は必要headerHeightが必要
    $('body,html').stop().animate({scrollTop:position}, 500);
  });

}



//SPメニュー
//-------------------------------------------------------------
function spMenu () {
  var $spMenu = $('.sp-menu'),
      $overlay = $('.overlay'),
      $menuTrigger = $('.menu-trigger'),
      $close = $('.close');

  $menuTrigger.on('click', function() {
    $spMenu.addClass('show');
    $overlay.addClass('show');
  });

  $close.on('click', function() {
    $spMenu.removeClass('show');
    $overlay.removeClass('show');
  });
}

//送信ボタン非活性
//-------------------------------------------------------------
function disabledSubmit () {

  const submit = document.getElementById('submit'),
        agree = document.getElementById('box');

  agree.addEventListener('click', function(){
    submit.classList.toggle('disabled');
  });

  submit.addEventListener('click', function(e){
    if(submit.classList.contains('disabled') != true) {
      return true;
    } else {
      e.preventDefault();
    }
  });

}

//送信ボタン非活性
//-------------------------------------------------------------
function disabledUnsubscribe () {

  const unsubscribe = document.getElementById('unsubscribe'),
      agree = document.getElementById('box');

  agree.addEventListener('click', function(){
    unsubscribe.classList.toggle('disabled');
  });

  unsubscribe.addEventListener('click', function(e){
    if(unsubscribe.classList.contains('disabled') != true) {
      return true;
    } else {
      e.preventDefault();
    }
  });

}


//errorクラス付与
//-------------------------------------------------------------
function addErrorClass () {
  var submit = document.getElementById('submit'),
      loginId = document.getElementById('loginId'),
      pwd = document.getElementById('pwd'),
      rePwd = document.getElementById('rePwd'),
      relationship = document.getElementById('relationship'),
      familyName = document.getElementById('familyName'),
      firstName = document.getElementById('firstName'),
      kanaFamily = document.getElementById('kanaFamily'),
      kanaFirst = document.getElementById('kanaFirst'),
      year = document.getElementById('year'),
      month = document.getElementById('month'),
      date = document.getElementById('date'),
      male = document.getElementById('male'),
      female = document.getElementById('female'),
      mail = document.getElementById('mail'),
      errorAnno = document.getElementById('errorAnno');

  submit.addEventListener('click', function() {

    if(submit.classList.contains('disabled')) {
      return false;
    }

    if(loginId != null) {
      //ログインID
      if(loginId.value === '' || loginId.value.length <= 0 || loginId.value.includes('@') === false) {
        loginId.parentNode.classList.add('error-wrap');
      } else {
        loginId.parentNode.classList.remove('error-wrap');
      }
    }


    if(pwd != null){
      //パスワード
      if(pwd.value === '' || pwd.value.length <= 0) {
        pwd.parentNode.classList.add('error-wrap');
      } else {
        pwd.parentNode.classList.remove('error-wrap');
      }
    }

    if(rePwd != null) {
      //パスワード再入力
      if(rePwd.value === '' || rePwd.value.length <= 0) {
        rePwd.parentNode.classList.add('error-wrap');
      } else {
        rePwd.parentNode.classList.remove('error-wrap');
      }
    }

    //続柄
    if(relationship != null) {
      if(relationship.value === '' || relationship.value.length <= 0) {
        relationship.parentNode.parentNode.classList.add('error-wrap');
        relationship.parentNode.classList.add('error');
      } else {
        relationship.parentNode.parentNode.classList.remove('error-wrap');
        relationship.parentNode.classList.remove('error');
      }
    }


    if(familyName != null) {
      //お名前（姓）
      if(familyName.value === '' || familyName.value.length <= 0) {
        familyName.parentNode.classList.add('error');
      } else {
        familyName.parentNode.classList.remove('error');
      }

      //お名前（名）
      if(firstName.value === '' || firstName.value.length <= 0) {
        firstName.parentNode.classList.add('error');
      } else {
        firstName.parentNode.classList.remove('error');
      }

      //姓か名のどちらかがエラーだったら、その親要素にerrorクラスを付与
      if(familyName.parentNode.classList.contains('error') || firstName.parentNode.classList.contains('error')) {
        firstName.parentNode.parentNode.classList.add('error-wrap');
      } else {
        firstName.parentNode.parentNode.classList.remove('error-wrap');
      }
    }


    if(kanaFamily != null) {
      //お名前（セイ）
      if(kanaFamily.value === '' || kanaFamily.value.length <= 0) {
        kanaFamily.parentNode.classList.add('error');
      } else {
        kanaFamily.parentNode.classList.remove('error');
      }

      //お名前（メイ）
      if(kanaFirst.value === '' || kanaFirst.value.length <= 0) {
        kanaFirst.parentNode.classList.add('error');
      } else {
        kanaFirst.parentNode.classList.remove('error');
      }

      //セイかメイのどちらかがエラーだったら、その親要素にerrorクラスを付与
      if(kanaFamily.parentNode.classList.contains('error') || kanaFirst.parentNode.classList.contains('error')) {
        kanaFamily.parentNode.parentNode.classList.add('error-wrap');
      } else {
        kanaFamily.parentNode.parentNode.classList.remove('error-wrap');
      }
    }


    if(year != null) {
      //生年月日（年）
      if(year.value === '' || year.value.length <= 0) {
        year.parentNode.classList.add('error');
      } else {
        year.parentNode.classList.remove('error');
      }

      //生年月日（月）
      if(month.value === '' || month.value.length <= 0) {
        month.parentNode.classList.add('error');
      } else {
        month.parentNode.classList.remove('error');
      }

      //生年月日（日）
      if(date.value === '' || date.value.length <= 0) {
        date.parentNode.classList.add('error');
      } else {
        date.parentNode.classList.remove('error');
      }

      //生年月日のいずれかがエラーだったら、その親要素にerrorクラスを付与
      if(year.parentNode.classList.contains('error') ||
          month.parentNode.classList.contains('error') ||
          date.parentNode.classList.contains('error')) {
        year.parentNode.parentNode.classList.add('error-wrap');
      } else {
        year.parentNode.parentNode.classList.remove('error-wrap');
      }
    }


    if(male != null) {
      //ラジオボタンが一つも選択されていなければ、その親要素にerrorクラスを付与
      if(male.checked === false && female.checked === false)  {
        male.parentNode.parentNode.classList.add('error-wrap');
      } else {
        male.parentNode.parentNode.classList.remove('error-wrap');
      }
    }


    if(mail != null) {
      //ラジオボタンが一つも選択されていなければ、その親要素にerrorクラスを付与
      if(mail.value === '' || mail.value.length <= 0)  {
        mail.parentNode.classList.add('error');
      } else {
        mail.parentNode.classList.remove('error');
      }
    }

    var errCount = document.querySelectorAll('.error').length;

    if(!errCount > 0) {
      errorAnno.classList.remove('show');
    } else {
      errorAnno.classList.add('show');
    }

  });

}



// --- ページの全データを読み込み後 ----------------------------------
$(window).on('load', function() {

  loadedPageFunc();
  checkDeviceAndWidthFunc();

});

// --- リサイズが走った場合 ----------------------------------------
$(window).on('resize', function(){

  checkDeviceAndWidthFunc();

});

// --- DOM生成後 -----------------------------------------------
$(function(){

  // 共通処理
  goToPageTopFunc('#pageTop');
  smoothScrollMoveFunc('a.scroll');
  spMenu();
  // addErrorClass();

  if(document.getElementById('pageRegist') != null) {
    disabledSubmit();
  }

  if(document.getElementById('pageUnsubscribe') != null) {
    disabledUnsubscribe();
  }

});
